@import "../../../asset/Theme.scss";

#home-carousel {
  position: relative;
  z-index: 1;
  // background: url(https://i.imgur.com/6axE29k.jpg) center center no-repeat;
  // background-size: cover;
  // background-image: linear-gradient(
  //   to right,
  //   #44137d,
  //   #44137d,
  //   #44137d,
  //   #44137d,
  //   #44137d,
  //   #570d79,
  //   #660574,
  //   #73006f,
  //   #8a0062,
  //   #9c0053,
  //   #a80044,
  //   #af1c34
  // );
  background: rgba($grey, 0.5);
  .container {
    max-width: 1430px;
  }

  h4 {
    font-size: 50px;
    margin-bottom: 15px;
    // color: #fff;
    line-height: 100%;
    letter-spacing: 0.5px;
    font-weight: 600;
    span {
      color: $red;
    }
  }
  p {
    font-size: 18px;
    margin-bottom: 15px;
    // color: #d5d5d5;
    color: rgba($black, 0.75);
  }
  img {
    filter: drop-shadow(10px 10px 5px $grey);
  }
  h4,
  p,
  a,
  .carousel-item .mask img {
    -webkit-animation-duration: 1s;
    animation-duration: 1.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .shadow-image {
    position: absolute;
    left: 0;
    top: 0;
    filter: grayscale(1) !important;
    opacity: 0.1;
  }
  .carousel-item {
    .mask {
      // position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-attachment: fixed;
    }

    height: 100%;
    // min-height: 550px;

    h4 {
      -webkit-animation-name: fadeInLeft;
      animation-name: fadeInLeft;
    }
    p {
      -webkit-animation-name: slideInRight;
      animation-name: slideInRight;
    }
    a {
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp;
    }
    .mask img {
      -webkit-animation-name: slideInRight;
      animation-name: slideInRight;
      display: block;
      height: auto;
      max-width: 100%;
    }
  }
}

.carousel-item {
  position: relative;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}
.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  opacity: 1;
}
.carousel-fade .carousel-item-left.active,
.carousel-fade .carousel-item-right.active {
  opacity: 0;
}
.carousel-fade .carousel-item-left.active,
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item-prev.active,
.carousel-fade .carousel-item.active {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-left.active,
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item-prev.active,
  .carousel-fade .carousel-item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.carousel-fade .carousel-item-left.active,
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item-prev.active,
.carousel-fade .carousel-item.active {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@media (max-width: 768px) {
  #home-carousel {
    .shadow-image {
      display: none !important;
    }
    .order-md-1 {
      position: absolute;
      z-index: 1;
      bottom: 2rem;
      height: auto;
      padding: 1rem;
      h4 {
        color: #fff;
        text-shadow: 1px 1px 1px black;
      }
      p {
        display: none;
      }
    }
  }
}
