@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;700;900&display=swap");
@import "~bootstrap/scss/bootstrap";

body {
  font-family: "Heebo", sans-serif;
}

// Use h2 for section title in the page
h1,
h2 {
  font-weight: 700;
  margin-bottom: 20px;
  display: inline-block;
  margin-bottom: 20px;
}

.btn {
  border-radius: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  svg {
    margin-left: 0.5rem;
  }
}

.dropdown-item {
  &:focus {
    color: white !important;
    background-color: $red;
  }
}

.hover-zoom-image {
  overflow: hidden;
  img {
    transition: transform 0.75s ease;
  }
  &:hover {
    cursor: pointer;
    overflow: hidden;
    img {
      transform: scale(1.2);
    }
  }
}

@keyframes encourage-click-animate {
  0% {
    // opacity: 0;
    margin-left: 0.6rem;
  }
  100% {
    // opacity: 1;
    margin-left: 0.75rem;
  }
}
