@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "./Item.scss";

.item-view {
  .tag-container {
    display: block;
    position: absolute;
    z-index: 2;
    .tag {
      display: block;
      position: relative;
      width: fit-content;
      top: 0.5rem;
      left: -0.5rem;
      div {
        padding: 0.2rem 0.5rem;
        margin-top: 0.5rem;
        color: $red;
        background-color: rgba(white, 0.9);
        border: 1px solid $red;
      }
    }
    .sale-tag {
      @include sale-tag;
      position: relative;
    }
  }

  .price {
    @include price;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 0.5rem;
    &.with-sale-price .base-price {
      font-size: 20px;
    }
  }

  .dropdown-menu {
    a {
      padding-left: 2rem;
      padding-right: 2rem;
      svg {
        margin-right: 0.5rem;
        vertical-align: baseline;
      }
    }
  }

  .item-image-view {
    height: 500px;
    width: auto;
  }

  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:focus,
  .image-gallery-thumbnail:hover,
  .image-gallery-icon:hover {
    border-color: $red;
  }
  .image-gallery-slide,
  .image-gallery-thumbnail-inner {
    overflow: hidden;
  }
  .image-gallery-slide img {
    height: 500px;
  }
  .image-gallery-thumbnail-inner img {
    width: auto;
    height: 120px;
    @include center-x;
  }
  button.image-gallery-icon:hover {
    color: $red;
  }
}
