.contact-use-image-map {
  margin-bottom: 1em;
  .image-div {
    position: relative;
  }

  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .image-div:hover .image {
    opacity: 0.3;
  }

  .image-div:hover .middle {
    opacity: 1;
  }

  .text {
    background-color: #343434;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
  }
}
