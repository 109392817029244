@import "../../../asset/Theme.scss";

.home-blog-section {
  background-color: $light-grey;
  .blog-card {
    display: block;
    position: relative;
    overflow: hidden;
    cursor: auto;
    .blog-resource-tag {
      z-index: 2;
      position: absolute;
      border-radius: 5px;
      left: 0.5rem;
      top: 0.5rem;
    }
    img {
      width: 100%;
    }
    .overlay {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: linear-gradient(rgba($black, 0) 30%, rgba($black, 0.9) 100%);
    }
    .title {
      font-weight: 700;
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0px;
      padding: 0.75rem;
      z-index: 2;
      color: white;
      p {
        margin-bottom: 0;
        text-shadow: 0.5px 0.5px 0.5px black;
      }
      hr {
        margin: 0.5rem 0;
      }
      svg {
        vertical-align: middle;
      }
      span {
        padding-left: 0.25rem;
        font-size: 14px;
        line-height: 26px;
        font-weight: 400;
        margin-right: 10px;
        color: $dark-grey;
      }
    }
  }
  .blog-intro .d-flex {
    margin-bottom: 1rem;
    p {
      padding-left: 1.5rem;
      margin: 0;
      span {
        color: $red;
      }
    }
  }
  h4 {
    font-weight: 400;
    margin-bottom: 1.5rem;
  }
}
