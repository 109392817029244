@import "../../../asset/Theme.scss";

$thumbnailHeight: 300px;

@mixin price {
  color: $red;
  font-weight: 300;
  margin-top: 5px;
  &.with-sale-price .base-price {
    text-decoration: line-through;
    color: $dark-grey;
    padding-left: 0.5rem;
  }
}

@mixin sale-tag {
  top: 0.5rem;
  left: -0.5rem;
  position: absolute;
  div {
    color: white;
    width: fit-content;
    background-color: $red;
    padding: 0.2rem 0.5rem;
  }
}

@mixin tag {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  div {
    font-size: 10pt;
    color: $dark-grey;
    background-color: $light-grey;
    padding: 0 0.5rem;
    border-radius: 7px;
    display: inline-block;
    margin-right: 0.25rem;
  }
}

.item-card {
  color: $black;
  text-decoration: none;
  position: relative;
  &:hover {
    .thumbnail {
      border: 1px solid $red;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }
    .thumbnail img {
      // transform: scale(0.95);
    }
  }

  .sale-tag {
    @include sale-tag;
  }

  .tag {
    @include tag;
  }

  .thumbnail {
    @include animate;
    overflow: hidden;
    position: relative;
    height: $thumbnailHeight;
    border: 1px solid $grey;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    img {
      @include animate;
      width: auto;
      height: $thumbnailHeight;
    }
  }
  .title {
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .price {
    @include price;
  }
}
