@import "../../../asset/Theme.scss";

.contact-bar {
  background-color: black;
  svg {
    cursor: pointer;
  }
  .contact-bar-item {
    width: fit-content;
    margin-left: auto;
    padding: 0.5rem 0;
    a {
      border-width: 0;
    }
  }
  .dropdown {
    display: inline-block;
    button {
      color: $grey;
      border: none;
      padding: 0;
    }
    .dropdown-menu {
      border-radius: 0;
      min-width: fit-content;
    }
    .dropdown-item {
      width: fit-content;
      margin: 0;
    }
  }
}

nav {
  width: 100%;
  .navbar-brand {
    width: 128px;
    // height: 128px;
    img {
      width: 128px;
    }
    p {
      margin: 0;
      text-transform: uppercase;
      // position: absolute;
      display: block;
      color: $dark-grey;
      font-size: 6pt;
    }
  }
  .nav-link {
    text-transform: uppercase;
  }
  .nav-link:hover {
    color: $red !important;
  }
  .dropdown {
    .dropdown-menu {
      -webkit-transition: all 0.25s;
      -moz-transition: all 0.25s;
      -ms-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;

      margin-top: 0;
      border-radius: 0;
    }
    .dropdown-item {
      padding-left: 2rem;
      padding-right: 2rem;
      &:hover {
        color: $red !important;
      }
      &:focus {
        color: white !important;
      }
    }
    &.show .dropdown-menu {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  .navbar-toggler {
    padding: 0.75rem 1rem;
    font-size: medium;
    border: none;

    span {
      padding: 0.5rem;
      vertical-align: middle;
    }
  }
}
