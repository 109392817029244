@import "../../../asset/Theme.scss";

.pdy-breadcrumb {
  height: 200px;
  background-color: $light-grey;
  h2,
  h3,
  h4 {
    text-transform: uppercase;
    margin: 0;
  }
  div {
    display: inline-block;
  }
  svg {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  a,
  span {
    text-decoration: none;
    color: $dark-grey;
    text-transform: uppercase;
    color: black;
  }
}
