$content-padding: 2rem;

$dark-grey: #87868b;
$grey: #d3d5d2;
$light-grey: #dcdddf4d;
$white: #e3e4e6;
$black: #212025;
$dark-red: #af1c34;
$red: #ed2525;

.form-check-input[type="checkbox"] {
  border-radius: 0;
  &:active,
  &:checked {
    border-color: black;
    background-color: black;
  }
  &:focus {
    border-color: $dark-grey;
    box-shadow: 0 0 0 0.25rem $light-grey;
  }
}
.form-check-label {
  font-weight: 300;
}
@mixin animate {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@mixin center-x {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}
