.home-category {
  .card-img-overlay {
    overflow: hidden;
    text-overflow: ellipsis;
    background: linear-gradient(
      to bottom,
      rgba(black, 0) 30%,
      rgba(black, 0.6) 100%
    );
  }
  .card-title {
    position: absolute;
    font-size: 1.6875rem;
    color: white;
    bottom: 0;
    span {
      display: block;
      font-size: 12px;
      color: rgba(white, 0.6);
      text-transform: uppercase;
    }
  }
}
